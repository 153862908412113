import * as fb from "../../firebase";
import moment from "moment";
import axios from "axios";
export default {
  state: {
    selectKioskAnalytics: "",
    selectedDuration: "Today",
    displayDate: "",
    todayBarChart: [
      { category: "8 AM", value: 0 },
      { category: "9 AM", value: 0 },
      { category: "10 AM", value: 0 },
      { category: "11 AM", value: 0 },
      { category: "12 PM", value: 0 },
      { category: "1 PM", value: 0 },
      { category: "2 PM", value: 0 },
      { category: "3 PM", value: 0 },
      { category: "4 PM", value: 0 },
      { category: "5 PM", value: 0 },
    ],
    pieChart: [
      { label: "Faqs", value: 0 },
      { label: "Maps", value: 0 },
      { label: "Forms", value: 0 },
      { label: "Payments", value: 0 },
      { label: "Hearings", value: 0 },
      { label: "Jury Duty", value: 0 },
    ],
    languageBarChart: [
      { category: "English", value: 0 },
      { category: "Spanish", value: 0 },
    ],
    pieChartTotal: 0,
    singleDatePicker: false,
    selectedSingleDate: null,
    weekDatePicker: false,
    selectedWeekDate: null,
    fiscalYearPicker: false,
    enteredFiscalYear: "",
    closeWithoutSelection: 0,
  },
  getters: {
    selectKioskAnalytics: (state) => state.selectKioskAnalytics,
    todayBarChart: (state) => state.todayBarChart,
    pieChart: (state) => state.pieChart,
    getPieChartTotal: (state) => state.pieChartTotal,
    languageBarChart: (state) => state.languageBarChart,
    selectedDuration: (state) => state.selectedDuration,
    singleDatePicker: (state) => state.singleDatePicker,
    selectedSingleDate: (state) => state.selectedSingleDate,
    weekDatePicker: (state) => state.weekDatePicker,
    selectedWeekDate: (state) => state.selectedWeekDate,
    displayDate: (state) => state.displayDate,
    fiscalYearPicker: (state) => state.fiscalYearPicker,
    enteredFiscalYear: (state) => state.enteredFiscalYear,
    closeWithoutSelection: (state) => state.closeWithoutSelection,
  },
  mutations: {
    setSelectKioskAnalytics(state, kioskid) {
      state.selectKioskAnalytics = kioskid;
    },
    setSelectedDuration(state, duration) {
      state.selectedDuration = duration;
    },
    setPieChartTotal(state, total) {
      state.pieChartTotal = total;
    },
    setSingleDatePicker(state, status) {
      state.singleDatePicker = status;
    },
    setSelectedSingleDate(state, date) {
      state.selectedSingleDate = date;
    },
    setWeekDatePicker(state, status) {
      state.weekDatePicker = status;
    },
    setSelectedWeekDate(state, dates) {
      state.selectedWeekDate = dates;
    },
    setDisplayDate(state, dateString) {
      state.displayDate = dateString;
    },
    setFiscalYearPicker(state, status) {
      state.fiscalYearPicker = status;
    },
    setEnteredFiscalYear(state, year) {
      state.enteredFiscalYear = year;
    },
    setcloseWithoutSelection(state, value) {
      state.closeWithoutSelection = value;
    },
    resetPieChart(state) {
      state.pieChart = [
        { label: "Faqs", value: 0 },
        { label: "Maps", value: 0 },
        { label: "Forms", value: 0 },
        { label: "Payments", value: 0 },
        { label: "Hearings", value: 0 },
        { label: "Jury Duty", value: 0 },
        { label: "Rent Court", value: 0 },
      ];
    },
    resetLanguageBarChart(state) {
      state.languageBarChart = [
        { category: "English", value: 0 },
        { category: "Spanish", value: 0 },
      ];
    },
    resetTodayBarChart(state) {
      state.todayBarChart = [
        { category: "8 AM", value: 0 },
        { category: "9 AM", value: 0 },
        { category: "10 AM", value: 0 },
        { category: "11 AM", value: 0 },
        { category: "12 PM", value: 0 },
        { category: "1 PM", value: 0 },
        { category: "2 PM", value: 0 },
        { category: "3 PM", value: 0 },
        { category: "4 PM", value: 0 },
        { category: "5 PM", value: 0 },
      ];
    },
  },
  actions: {
    getKioskAnalytics({ commit, getters, dispatch }, duration) {
      return new Promise((resolve, reject) => {
        console.log("What is the duration: ", duration);
        let analyticsObj = {};
        let usersessionList = [];
        const today = new Date();
        let startDate, endDate;
        let startDateOld, endDateOld;
        if (duration.type === "Today") {
          today.setHours(0, 0, 0, 0);
          startDate = today.toISOString();
          const today1 = new Date();
          const tomorrow = new Date(today1);
          tomorrow.setDate(today1.getDate() + 1);
          endDate = tomorrow.toISOString();
          commit("setDisplayDate", moment(startDate).format("L"));
        } else if (duration.type === "Yesterday") {
          today.setHours(0, 0, 0, 0);
          endDate = today.toISOString();
          const tempDate = new Date();
          const yesterday = new Date(tempDate);
          yesterday.setDate(tempDate.getDate() - 1);
          yesterday.setHours(0, 0, 0, 0);
          startDate = yesterday.toISOString();
          console.log("AT yesterday: ", startDate, endDate);
          commit("setDisplayDate", moment(startDate).format("L"));
        } else if (duration.type === "Select Date") {
          startDate = getters.selectedSingleDate.startDate.toISOString();
          endDate = getters.selectedSingleDate.endDate.toISOString();
          commit("setDisplayDate", moment(startDate).format("L"));
        } else if (duration.type === "This Week") {
          const weekToday = new Date();
          const currentDayOfWeek = weekToday.getDay();
          const startW = new Date(weekToday);
          startW.setDate(weekToday.getDate() - currentDayOfWeek);
          startDate = startW.toISOString();
          const endW = new Date(weekToday);
          endW.setDate(weekToday.getDate() + (6 - currentDayOfWeek));
          endDate = endW.toISOString();
          commit(
            "setDisplayDate",
            moment(startDate).format("L") + " - " + moment(endDate).format("L")
          );
        } else if (duration.type === "Select Week") {
          startDate = getters.selectedWeekDate.startDate.toISOString();
          endDate = getters.selectedWeekDate.endDate.toISOString();
          commit(
            "setDisplayDate",
            moment(startDate).format("L") + " - " + moment(endDate).format("L")
          );
        } else if (duration.type === "2024") {
          const annualToday = new Date();
          const oneYearAgo = new Date(annualToday);
          oneYearAgo.setFullYear(annualToday.getFullYear() - 1);
          startDateOld = oneYearAgo.toISOString();
          endDateOld = annualToday.toISOString();
          const year = new Date().getFullYear();
          startDate = `${year}-01-01T00:00:00.000Z`;
          endDate = `${year}-12-31T23:59:59.999Z`;
          commit("setDisplayDate", "Annual " + annualToday.getFullYear());
        } else if (duration.type === "Fiscal Year") {
          startDate = getters.enteredFiscalYear.startDate.toISOString();
          endDate = getters.enteredFiscalYear.endDate.toISOString();
          commit(
            "setDisplayDate",
            moment(startDate).format("L") + " - " + moment(endDate).format("L")
          );
        } else {
          console.log("Custom Duration");
        }
        let config1 = {
          method: "get",
          maxBodyLength: Infinity,
          url: `https://cms.mdcourts.arsconnect.com/user-sessions?kioskId=${getters.selectKioskAnalytics}&sessionStartTime_gte=${startDate}&sessionStartTime_lte=${endDate}&_limit=-1`,
          headers: {},
        };

        let config2 = {
          method: "get",
          maxBodyLength: Infinity,
          url: `https://cms.mdcourts.arsconnect.com/qnas?kioskID=${getters.selectKioskAnalytics}&timeStamp_gte=${startDate}&timeStamp_lte=${endDate}&_limit=-1`,
          headers: {},
        };
        axios
          .request(config1)
          .then((response) => {
            usersessionList = response.data;
            axios
              .request(config2)
              .then((response) => {
                analyticsObj = {
                  qnaAnalytics: response.data,
                  userSessionAnalytics: usersessionList,
                  totalQuestionsAsked: response.data.length,
                  totalUserSessions: usersessionList.length,
                  overallPerformance: 0,
                };
                commit("setKioskAnalytics", analyticsObj);
                commit("resetTodayBarChart");
                commit("resetPieChart");
                commit("resetLanguageBarChart");
                if (usersessionList.length !== 0) {
                  if (duration.type === "Today") {
                    dispatch("createTodayBarChart", usersessionList);
                  } else if (duration.type === "Yesterday") {
                    dispatch("createTodayBarChart", usersessionList);
                  } else if (duration.type === "Select Date") {
                    dispatch("createTodayBarChart", usersessionList);
                  }
                  dispatch("createPieChart", usersessionList);
                  dispatch("createLanguageBarChart", usersessionList);
                } else {
                  //
                }
                resolve("Kiosk analytical data fetched");
              })
              .catch((error) => {
                reject(error.message);
              });
          })
          .catch((error) => {
            reject(error.message);
          });
        resolve("Kiosk analytical data fetched");
      });
    },
    createTodayBarChart({ getters, commit }, usersessionList) {
      usersessionList.forEach((session) => {
        let day = moment(session.sessionEndTime).format("H");
        switch (day) {
          case "8":
            var eight = getters.todayBarChart.find(
              (item) => item.category === "8 AM"
            );
            eight.value = eight.value + 1;
            break;
          case "9":
            var nine = getters.todayBarChart.find(
              (item) => item.category === "9 AM"
            );
            nine.value = nine.value + 1;
            break;
          case "10":
            var ten = getters.todayBarChart.find(
              (item) => item.category === "10 AM"
            );
            ten.value = ten.value + 1;
            break;
          case "11":
            var eleven = getters.todayBarChart.find(
              (item) => item.category === "11 AM"
            );
            eleven.value = eleven.value + 1;
            break;
          case "12":
            var twelve = getters.todayBarChart.find(
              (item) => item.category === "12 PM"
            );
            twelve.value = twelve.value + 1;
            break;
          case "13":
            var thirteen = getters.todayBarChart.find(
              (item) => item.category === "1 PM"
            );
            thirteen.value = thirteen.value + 1;
            break;
          case "14":
            var fourteen = getters.todayBarChart.find(
              (item) => item.category === "2 PM"
            );
            fourteen.value = fourteen.value + 1;
            break;
          case "15":
            var fifteen = getters.todayBarChart.find(
              (item) => item.category === "3 PM"
            );
            fifteen.value = fifteen.value + 1;
            break;
          case "16":
            var sixteen = getters.todayBarChart.find(
              (item) => item.category === "4 PM"
            );
            sixteen.value = sixteen.value + 1;
            break;
          case "17":
            var seventeen = getters.todayBarChart.find(
              (item) => item.category === "5 PM"
            );
            seventeen.value = seventeen.value + 1;
            break;
        }
      });
    },
    createPieChart({ getters, commit }, usersessionList) {
      commit("resetPieChart");
      usersessionList.forEach((session) => {
        const data = JSON.parse(session.userSession);

        var faq = getters.pieChart.find((item) => item.label === "Faqs");
        faq.value =
          faq.value + data.filter((item) => item.response === "faqs").length;

        var form = getters.pieChart.find((item) => item.label === "Forms");
        form.value =
          form.value + data.filter((item) => item.response === "forms").length;

        var map = getters.pieChart.find((item) => item.label === "Maps");
        map.value =
          map.value + data.filter((item) => item.response === "maps").length;

        var pay = getters.pieChart.find((item) => item.label === "Payments");
        pay.value =
          pay.value +
          data.filter((item) => item.response === "payments").length;

        var hearing = getters.pieChart.find(
          (item) => item.label === "Hearings"
        );
        hearing.value =
          hearing.value +
          data.filter((item) => item.response === "todayshearing").length;

        var juryDuty = getters.pieChart.find(
          (item) => item.label === "Jury Duty"
        );
        juryDuty.value =
          juryDuty.value +
          data.filter((item) => item.response === "Jury Assembly Room").length;

        //{ label: 'Rent Court', value: 0}
        var rentCourt = getters.pieChart.find(
          (item) => item.label === "Rent Court"
        );
        rentCourt.value =
          rentCourt.value +
          data.filter((item) => item.response === "Rent Court").length;
        commit(
          "setPieChartTotal",
          faq.value +
            form.value +
            map.value +
            pay.value +
            hearing.value +
            juryDuty.value +
            rentCourt.value
        );
      });
    },
    createLanguageBarChart({ getters }, usersessionList) {
      usersessionList.forEach((session) => {
        const data = JSON.parse(session.userSession);
        var english = getters.languageBarChart.find(
          (item) => item.category === "English"
        );
        english.value = usersessionList.length;

        var spanish = getters.languageBarChart.find(
          (item) => item.category === "Spanish"
        );
        spanish.value =
          spanish.value +
          data.filter((item) => item.response === "Language changed to Spanish")
            .length;
      });
    },
  },
};
